<template>
  <v-container>
    <v-row justify="space-between">
      <v-form class="row" ref="form" v-model="valid" lazy-validation>
        <!-- avatar -->
        <div class="uk-width-1-3@m px-3 text-center mb-5">
          <v-avatar
            class="profile"
            color="grey"
            size="164"
            tile
            style="border: 2px solid grey; border-radius: 10px !important"
          >
            <v-img :src="avatarPreview || getBased(userInfos.avatar)"></v-img>
          </v-avatar>
          <v-file-input
            :label="$t('form.personalPicture')"
            filled
            accept=".jpg,.png"
            :append-icon="cameraIcon"
            prepend-icon=""
            @change="onChangePic"
            @click:clear="remove"
            v-model="newAvatar"
          ></v-file-input>
          <router-link :to="{ name: 'changePassword' }">
            <base-button :options="{ block: true }">{{
              $t("form.changePassword")
            }}</base-button>
          </router-link>
        </div>
        <div class="uk-width-1-3@m px-3">
          <v-text-field
            v-model="userInfos.firstName"
            :label="$t('form.firstName')"
            :rules="firstNameRules"
            required
          ></v-text-field>
          <v-text-field
            v-model="userInfos.lastName"
            :label="$t('form.lastName')"
            :rules="lastNameRules"
            required
          ></v-text-field>
          <v-text-field
            v-model="userInfos.email"
            :label="$t('form.email')"
            :rules="emailRules"
            required
            :disabled="true"
            :readonly="true"
          ></v-text-field>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="userInfos.birthDate"
                :label="$t('form.birthDate')"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="userInfos.birthDate"
              no-title
              scrollable
              color="#69d1b6"
            >
              <v-spacer></v-spacer>
              <v-btn text @click="menu = false">
                Cancel
              </v-btn>
              <v-btn
                color="#69d1b6"
                @click="$refs.menu.save(userInfos.birthDate)"
                dark
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="uk-width-1-3@m px-3">
          <v-select
            v-model="userInfos.country"
            :items="countries"
            :label="$t('form.country')"
          ></v-select>
          <v-text-field
            v-model="userInfos.phone"
            :label="$t('form.phoneNumber')"
            prefix="+963"
            :rules="phoneRules"
            :type="'number'"
            required
          ></v-text-field>
          <v-text-field
            v-model="userInfos.identityNumber"
            :label="$t('form.IDNumber')"
            :rules="identityRules"
            required
            :type="'number'"
          ></v-text-field>
          <base-button
            @click="edituserInfosMethod"
            :options="{ isLoading: loading, block: true }"
          >
            {{ $t("form.submit") }}
          </base-button>
        </div>
      </v-form>
    </v-row>
  </v-container>
</template>

<script>
import BaseButton from "../../../core/Base/Buttons/BaseButton";
import { mapActions, mapState } from "vuex";

export default {
  name: "PersonalInfo",

  components: {
    BaseButton
  },

  data() {
    return {
      // form validation
      valid: true,
      firstNameRules: [(v) => !!v || this.$t("required.firstName")],
      lastNameRules: [(v) => !!v || this.$t("required.lastName")],
      emailRules: [
        (v) => !!v || this.$t("required.email"),
        (v) => /.+@.+/.test(v) || this.$t("required.validEmail")
      ],
      identityRules: [(v) => !!v || this.$t("required.IDNumber")],
      phoneRules: [(v) => !!v || this.$t("required.phoneNumber")],
      passwordRules: [
        (v) => !!v || this.$t("required.password"),
        (v) => v.length >= 10 || this.$t("required.validPassword")
      ],

      loading: false,
      menu: false,
      countries: ["syria", "Lebanon", "UAE"],
      newAvatar: [],
      avatarPreview: ""
    };
  },

  created() {
    this.getUserInfos();
  },

  methods: {
    ...mapActions("Profile", ["getUserInfos", "editUserInfos"]),
    onChangePic(e) {
      this.newAvatar = e || [];
      if (e && e.size > 0) {
        return (this.avatarPreview = URL.createObjectURL(e));
      }
    },
    remove() {
      this.avatarPreview = "";
      this.newAvatar = [];
    },
    edituserInfosMethod() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      const formdata = new FormData();
      formdata.append("first_name", this.userInfos.firstName);
      formdata.append("last_name", this.userInfos.lastName);
      formdata.append("phone", this.userInfos.phone);
      formdata.append("country", this.userInfos.country);
      formdata.append("identity_number", this.userInfos.identityNumber);
      formdata.append("birthday", this.userInfos.birthDate);
      if (this.newAvatar.size > 0) formdata.append("avatar", this.newAvatar);
      this.editUserInfos(formdata)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

  computed: {
    ...mapState("Profile", ["userInfos"])
  }
};
</script>

<style>
.v-input {
  padding: 10px;
}
a:hover {
  text-decoration: none !important;
}
</style>
