<!-- bact 37 -->
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="studentCourses"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-btn
          v-if="item.course.type === 'offline'"
          depressed
          color="teal lighten-3  white--text"
          :href="'#modal-courses-1'"
          uk-toggle
          @click="selectCourseModalMethod(item.course.id)"
          ><b>{{ $t("coursesList.courseInfo") }}</b>
        </v-btn>
        <router-link v-else :to="{ path: '/courses/' + item.course.id }">
          <v-btn depressed color="teal lighten-3  white--text">
            <b>{{ $t("startNow") }}</b>
          </v-btn>
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RegisteredCoursesItem",

  props: ["studentCourses"],

  data() {
    return {
      headers: [
        { text: this.$t("table.courseTitle"), align: "center",value: "course.title" },
        { text: this.$t("table.courseActive"), align: "center", value: "course.active" },
        { text: this.$t("table.isCompleted"), align: "center", value: "isCompleted" },
        { text: this.$t("table.price"), align: "center", value: "course.price" },
        { text: this.$t("table.courseType"), align: "center", value: "course.type" },
        { text: this.$t("table.courseStatus"), align: "center", value: "course.status" },
        { text: this.$t("coursesList.courseInfo"), align: "center", value: "action" }
      ]
    };
  },

  methods: {
    ...mapActions("CourseInfoModal", ["selectCourseModal"]),
    selectCourseModalMethod(id) {
      this.selectCourseModal(id);
    }
  }
};
</script>

<style lang="scss" scope>
.image-container {
  border-radius: 25px;
  .list {
    padding: 0 0 0 20px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 25px;
  }
}
a:hover {
  text-decoration: none;
}
th,
td {
  font-size: 17px !important;
}
.v-data-table__mobile-row:nth-of-type(1) {
  border-top: 3px solid #000;
}
</style>
