<template>
  <v-btn @click="click()"
         :color="options.color ? options.color : this.primaryColor"
         :block="options.block ? options.block : false"
         :loading="options.isLoading ? options.isLoading : false"
         class="white--text bact-button"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    options: {
      block: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: '',
      }
    }
  },
  methods: {
    click() {
      this.$emit('click');
    }
  }
}
</script>

<style scoped>
    .bact-button {
        border-radius: 20px !important;
    }
</style>
