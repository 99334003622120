<template>
  <div id="parent-component">
    <v-tabs v-model="tab" grow show-arrows centered class="d-flex flex-column">
      <!-- personal info -->
      <v-tab href="#personal-info">
        {{ $t("tab.personalInfo") }}
      </v-tab>
      <v-tab-item value="personal-info">
        <personal-info></personal-info>
      </v-tab-item>
      <!-- wish list -->
      <v-tab href="#wish-list">
        {{ $t("tab.wishList") }}
      </v-tab>
      <v-tab-item value="wish-list">
        <wish-list></wish-list>
      </v-tab-item>
      <!-- registered courses -->
      <v-tab href="#registered-courses">
        {{ $t("tab.registeredCourses") }}
      </v-tab>
      <v-tab-item value="registered-courses">
        <registered-courses></registered-courses>
      </v-tab-item>
      <!-- bills -->
      <v-tab href="#bills">
        {{ $t("tab.bills") }}
      </v-tab>
      <v-tab-item value="bills">
        <bills></bills>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import RegisteredCourses from "../Components/RegisteredCourses";
import PersonalInfo from "../Components/PersonalInfo.vue";
import WishList from "../Components/WishList.vue";
import Bills from "../Components/Bills";

export default {
  name: "EditProfile",

  components: {
    PersonalInfo,
    WishList,
    RegisteredCourses,
    Bills
  },

  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      }
    }
  }
};
</script>

<style lang="scss">
.v-tab {
  letter-spacing: normal;
  font-size: 18px !important;
}
.v-tabs-bar {
  margin-bottom: 30px !important;
  * {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
