<template>
  <v-container id="bills">
    <v-row class="d-flex flex-column text-center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="bills"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.action="{ item }">
            <v-btn
              v-if="item.status === 'pending'"
              depressed
              color="teal lighten-3  white--text"
              @click="PayBillMethod(item.id)"
              ><b>{{ $t("table.pay") }}</b>
            </v-btn>
            <v-btn v-else depressed color="teal lighten-3 no-hover white--text">
              <b>{{ $t("table.paid") }}</b>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Bills",

  data() {
    return {
      headers: [
        {
          text: this.$t("table.courseTitle"),
          align: "center",
          value: "courses_title"
        },
        { text: this.$t("table.amount"), align: "center", value: "amount" },
        { text: this.$t("table.notes"), align: "center", value: "note" },
        { text: this.$t("table.file"), align: "center", value: "file" },
        { text: this.$t("table.status"), align: "center", value: "status" },
        { text: this.$t("table.type"), align: "center", value: "type" },
        { text: this.$t("table.payment"), align: "center", value: "action" }
      ]
    };
  },

  created() {
    this.getBills();
  },

  methods: {
    ...mapActions("Profile", ["getBills", "payBill"]),
    PayBillMethod(billId) {
      this.payBill(billId)
        .then(() => {
          this.getBills();
        })
        .catch(() => {});
    }
  },

  computed: {
    ...mapState("Profile", ["bills"])
  }
};
</script>

<style>
.no-hover {
  cursor: text;
}
</style>
