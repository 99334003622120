<template>
  <div class="registeredCourses-list mt-10">
    <v-container>
      <div
        class="list"
        flat
        tile
        v-for="wishItem in allWishes"
        :key="wishItem.id"
        style="flex-wrap: wrap;"
      >
        <wish-list-item :wishItem="wishItem"></wish-list-item>
      </div>
      <!-- course modal info -->
      <course-info-modal></course-info-modal>
    </v-container>
  </div>
</template>

<script>
import WishListItem from "./WishListItem";
import CourseInfoModal from "../../../core/CourseInfoModal/CourseInfoModal";
import { mapActions, mapState } from "vuex";

export default {
  name: "WishList",

  components: { WishListItem, CourseInfoModal },

  data: function() {
    return {};
  },

  created() {
    this.getAllWishes();
  },

  methods: {
    ...mapActions("Profile", ["getAllWishes"])
  },

  computed: {
    ...mapState("Profile", ["allWishes"])
  }
};
</script>

<style lang="scss" scoped>
.registeredCourses-list {
  background-color: #fff;
}
</style>
