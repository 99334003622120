<!-- bact 37 -->
<template>
  <div class="registeredCourses-list mt-10">
    <v-container>
      <div class="list" flat tile style="flex-wrap: wrap;">
        <RegisteredCoursesItem :studentCourses="studentCourses" />
      </div>
    <!-- course modal info -->
    <course-info-modal></course-info-modal>
    </v-container>
  </div>
</template>

<script>
import RegisteredCoursesItem from "./RegisteredCoursesItem";
import CourseInfoModal from "../../../core/CourseInfoModal/CourseInfoModal";
import { mapActions, mapState } from "vuex";

export default {
  name: "RegisteredCourses",

  components: { RegisteredCoursesItem, CourseInfoModal },

  data: function() {
    return {};
  },

  created() {
    this.getStudentCourses();
  },

  methods: {
    ...mapActions("Profile", ["getStudentCourses"]),
  },

  computed: {
    ...mapState("Profile", ["studentCourses"])
  }
};
</script>

<style lang="scss" scoped>
.registeredCourses-list {
  background-color: #fff;
}
</style>
