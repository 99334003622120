<template>
  <v-row class="my-4 list align-center">
    <v-col class="d-flex justify-space-between justify-md-start align-center">
      <div class="mx-5">
        <v-img
          :src="getBased(wishItem.course_thumbnail)"
          class="image-container"
          width="250px"
          height="150px"
        />
      </div>
      <h2 class="d-flex ma-0">
        {{ wishItem.course_title }}
      </h2>
    </v-col>
    <v-col class="d-flex justify-space-between justify-md-end mb-3">
      <div>
        <v-btn
          depressed
          color="teal lighten-3  white--text"
          :href="'#modal-courses-1'"
          uk-toggle
          @click="selectCourseModalMethod(wishItem.course_id)"
          ><b>{{ $t("coursesList.courseInfo") }}</b>
        </v-btn>
      </div>
      <div>
        <v-btn
          @click="removeWishMethod(wishItem.course_id)"
          depressed
          color="error lighten-3 mx-2  white--text"
          ><b>{{ $t("removedFromWishList") }}</b>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "WishListItem",

  props: ["wishItem"],

  data() {
    return {
      img: require("../../../assets/img/ECourses/photo-1457904375453-3e1fc2fc76f4.png")
    };
  },

  methods: {
    ...mapActions("CourseInfoModal", ["selectCourseModal"]),
    ...mapActions("Profile", ["removeWish"]),
    selectCourseModalMethod(id) {
      this.selectCourseModal(id);
    },
    removeWishMethod(id) {
      this.removeWish(id);
    }
  }
};
</script>

<style lang="scss">
a:hover {
  text-decoration: none;
}
.image-container {
  border-radius: 25px;
  .list {
    padding: 0 0 0 20px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 25px;
  }
}
</style>
